import React, { useState, useEffect, useRef, useContext } from 'react';
import { recognizeSpeech, stopContinuousRecognitionAsync } from '../speechToText';
import { synthesizeSpeech } from '../textToSpeech';
import {
  Modal,
  TextField,
  PrimaryButton,
  DefaultButton,
  Stack,
  Dropdown,
  IconButton,
  mergeStyleSets,
  getTheme
} from '@fluentui/react';
import { TeamsFxContext } from "../Context";



const CommentModal = ({ isOpen, onClose, onSave, mode, comment, user, chat = false, isSaving }) => {
  const { apiClient } = useContext(TeamsFxContext);
  const [shift, setShift] = useState(comment?.Shift || '');
  const [footprint, setFootprint] = useState(comment?.Footprint || '');
  const [currentUser, setCurrentUser] = useState(user);
  const [commentText, setCommentText] = useState(comment?.Comment || '');
  const [totalDowntime, setTotalDowntime] = useState(comment?.TotalDowntime || '');
  const [alarms, setAlarms] = useState(comment?.Alarms || '');
  const [errorMessages, setErrorMessages] = useState({
    shift: '',
    footprint: '',
    totalDowntime: '',
    alarms: '',
    comment: ''
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isListening, setIsListening] = useState(false);

  // UseRefs to keep the current state values updated during the conversation loop
  const shiftRef = useRef(shift);
  const footprintRef = useRef(footprint);
  const totalDowntimeRef = useRef(totalDowntime);
  const alarmsRef = useRef(alarms);
  const commentTextRef = useRef(commentText);
  const isListeningRef = useRef(isListening);
  const voiceVolume =5;

  // Update refs when states change
  useEffect(() => {
    shiftRef.current = shift;
  }, [shift]);

  useEffect(() => {
    footprintRef.current = footprint;
  }, [footprint]);

  useEffect(() => {
    totalDowntimeRef.current = totalDowntime;
  }, [totalDowntime]);

  useEffect(() => {
    alarmsRef.current = alarms;
  }, [alarms]);

  useEffect(() => {
    commentTextRef.current = commentText;
  }, [commentText]);

  useEffect(() => {
    isListeningRef.current = isListening;
  }, [isListening]);
  
  const footprintOptions = [
    { key: '17-DL-023L', text: '17-DL-023L' },
    { key: '17-DL-023R', text: '17-DL-023R' },
    { key: '17-DL-024L', text: '17-DL-024L' },
    { key: '17-DL-024R', text: '17-DL-024R' },
    { key: '17-DL-025L', text: '17-DL-025L' },
    { key: '17-DL-025R', text: '17-DL-025R' },
    { key: '17-DL-026L', text: '17-DL-026L' },
    { key: '17-DL-026R', text: '17-DL-026R' },
  ];

  const shiftOptions = [
    { key: '1', text: '1' },
    { key: '2', text: '2' },
    { key: '3', text: '3' },
  ];

  if (chat) {
    footprintOptions.push({ key: comment?.Footprint, text: comment?.Footprint });
    shiftOptions.push({ key: comment?.Shift, text: comment?.Shift });
  }

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  useEffect(() => {
    if (comment) {
      setShift(comment.Shift || '');
      setFootprint(comment.Footprint || '');
      setCommentText(comment.Comment || '');
      setTotalDowntime(comment.TotalDowntime || '');
      setAlarms(comment.Alarms || '');
    }
  }, [comment]);

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'totalDowntime':
        setTotalDowntime(value);
        break;
      case 'alarms':
        setAlarms(value);
        break;
      case 'comment':
        setCommentText(value);
        break;
      case 'shift':
        setShift(value);
        break;
      case 'footprint':
        setFootprint(value);
        break;
      default:
        break;
    }
    setErrorMessages((prev) => ({ ...prev, [name]: '' }));
  };

  const handleDropdownChange = (event, option, setter, fieldName) => {
    setter(option.key);
    setErrorMessages((prev) => ({ ...prev, [fieldName]: '' }));
  };

  // Main function that keeps the conversation going in a loop
  const startConversationLoop = async () => {    

    let stopConversation = false;
    let missingData = ['shift', 'footprint', 'downtime', 'alarms'];

    // Step 0: Check for microphone permissions
    try {      
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      console.error("Microphone permission denied or error: ", error);
      await synthesizeSpeechWrapper("Microphone access is required. Please enable microphone permissions to continue.");
      return;
    }

    setIsListening(true); // Start listening
    isListeningRef.current = true;

    if (mode === 'edit') {
      await synthesizeSpeechWrapper(`Please tell me the comments you want to update.`);    
      await handleEditMode();
      return;
    }
  
    while (!stopConversation) {
      try {
        setIsListening(true); // Start listening
        isListeningRef.current = true;
        // Step 1: Capture speech input
        const recognizedText = await recognizeSpeechWrapper();
        
        // Step 2: Check for exit conditions
                      
        if (await confirmCancel(recognizedText)) {
          stopConversation = true;
          await synthesizeSpeechWrapper("Updates cancelled by user.");
          await clearFields();
          break;
        }

        // Step 3: Process the input  
        const prompt = await buildOpenAiPrompt(recognizedText, missingData);   
        const data = await extractDataFromAI(prompt);
        missingData = await updateDataAndIdentifyMissingFields(data);

        // Step 4: Check if all required fields are present
        if (missingData.length === 0) {
          // All mandatory fields have been identified
          const alertMessage = await buildSystemPrompt(missingData);
          await synthesizeSpeechWrapper(alertMessage);
          
          // Await user confirmation
          const confirmation = await recognizeSpeechWrapper();
          
          if (!await confirmCancel(confirmation)) {
            await synthesizeSpeechWrapper(`Ok, I will update this under your name '"${currentUser}". Please tell me the comments you want to update.`);            
            await handleEditMode();
            stopConversation = true;
            break;
          } else {
            await synthesizeSpeechWrapper("Okay, let's try again.");
            await clearFields();
            missingData = ['shift', 'footprint', 'downtime', 'alarms'];
          }
        } 
        else{
          // Some fields are still missing, build a prompt for those fields
          const systemMessage = await buildSystemPrompt(missingData);         
          await synthesizeSpeechWrapper(systemMessage);        
        }
      } catch (error) {
        console.error("Error saving data:", error);        
        await synthesizeSpeech("An error occurred. Please try again.");
        await clearFields();
      }
      finally{
      setIsListening(false); // Stop listening after recognition is complete
      isListeningRef.current = false;
      await stopContinuousRecognitionAsync();
      }
    }    
  };

  const synthesizeSpeechWrapper = async (message) => {
    try {                  
      await synthesizeSpeech(message);      
    } finally {
      await stopContinuousRecognitionAsync();
    }
   
  };

  const recognizeSpeechWrapper = async () => {
    try {
      const recognizedText = await recognizeSpeech();  
      return recognizedText;
    } finally {
    }
  };
  

  
  // Helper function for edit mode
  const handleEditMode = async () => {            
    const comments = await recognizeSpeechWrapper();    
    setCommentText(comments);    
    await synthesizeSpeechWrapper(`I have updated the comment: "${comments}". Please confirm if you would like to save?`);      
    const response = await recognizeSpeechWrapper(); 
    await confirmSave(response);
  };

  const confirmSave = async (text) => {
    if (!await confirmCancel(text)) {
      await handleSave();
      await synthesizeSpeech('Okay, Comments Saved');
    } else {
      await synthesizeSpeech('Okay, no comment will be added.');
      await handleCancel();
    }
  };
  
  const confirmCancel = async (recognizedText) => {
    const prompt = await buildIntentPrompt(recognizedText);
    const response = await extractDataFromAI(prompt); 
    return response.intent.toLowerCase().includes("cancel") ;    
  };

  const buildIntentPrompt = async (text) => {
    let prompt = `Analyze the following text and determine if the user intends to cancel or stop the update. If the user expresses any form of cancellation, refusal, or hesitation, return the intent as "cancel." Otherwise, return "proceed."
    \nText: "${text}"\n\nReturn an object with the extracted information.`;
    return prompt;
  };

  const extractDataFromAI = async (prompt) => {     
    try {
      const response = await apiClient.post("aiService", {prompt});  
      if(response.status===200){
        return response.data.text;
      } 
      return {};  
    } catch (err) {
      console.error('Error calling OpenAI API:', err);
      return {};
    }
  };

  const updateDataAndIdentifyMissingFields = async (data) => {
    const missingFields = [];
    // Update shift and track if missing
    if (data.Shift && !shiftRef.current) {
      setShift(data.Shift)
      shiftRef.current = data.Shift;
    } else if (!shiftRef.current) {
      missingFields.push('shift');
    }

    // Update footprint and track if missing
    if (data.Footprint && !footprintRef.current) {
      setFootprint(data.Footprint);
      footprintRef.current = data.Footprint;
    } else if (!footprintRef.current) {
      missingFields.push('footprint');
    }

    // Update alarms and track if missing
    if (data.Alarms && !alarmsRef.current) {
      setAlarms(data.Alarms);
      alarmsRef.current = data.Alarms;
    } else if (!alarmsRef.current) {
      missingFields.push('alarms');
    }

    // Update downtime and track if missing
    if (data.Downtime && !totalDowntimeRef.current) {
      setTotalDowntime(data.Downtime);
      totalDowntimeRef.current = data.Downtime;
    } else if (!totalDowntimeRef.current) {
      missingFields.push('downtime');
    }

    // Return remaining missing fields
    return missingFields;
  };

  const buildOpenAiPrompt = async (userInput, missingData) => {
    let prompt = `Extract the following information from the text:\n`;

    // Shift instructions
    if (missingData.includes('shift')) {
      prompt += `1. Shift: Ensure the shift is one of the following options: '${shiftOptions.map(option => option.key).join("', '")}', "first", "second", "third". Convert "first" to '1', "second" to '2', and "third" to '3'. This is text field . \n`;
    }

    // Footprint instructions
    if (missingData.includes('footprint')) {
      prompt += `2. Footprint: Ensure the footprint matches one of the following options: '${footprintOptions.map(option => option.key).join("', '")}', or shorter formats like '23 R', '23L'. Convert any shorter formats like '23 R' or '24 R' to '17-DL-023R' or '17-DL-024R', respectively. \n`;
    }

    // Downtime instructions
    if (missingData.includes('downtime')) {
      prompt += `3. Downtime: Ensure the downtime is a number representing the time in minutes. Output should be string as example if downtime is 20 then '20'\n`;
    }

    // Alarms instructions
    if (missingData.includes('alarms')) {
      prompt += `4. Alarms: Extract alarms from the input as a number. Output should be string as example if alarms is 20 then '20'\n`;
    }

    prompt += `\nText: "${userInput}"\n\nReturn an object with the extracted information.  If not data is extracted for any fields it should return '' and not 0`;

    return prompt;
  };

  const buildSystemPrompt = async (missingData) => {      
    const foundFields = [];
  
    // Check if fields are available
    if (shiftRef.current) foundFields.push(`shift ${shiftRef.current}`);
    if (footprintRef.current) foundFields.push(`footprint ${footprintRef.current}`);
    if (totalDowntimeRef.current) foundFields.push(`downtime: ${totalDowntimeRef.current} minutes`);
    if (alarmsRef.current) foundFields.push(`alarms: ${alarmsRef.current}`);
  
    // Construct alert message based on missingData length
    let alertMessage = missingData.length === 4
      ? "No Alerts Found."
      : foundFields.length > 0 
        ? `I found an alert for ${foundFields.join(", ")}. `
        : "";
  
    // Add the missing data prompt
    alertMessage += missingData.length > 0
      ? `Please tell me the ${missingData.join(" and ")}.`
      : "Is this the one you want to update?";
  
    return alertMessage;
  };
  

  const handleSave = async () => {
    let valid = true;
    const newErrorMessages = { shift: '', footprint: '', totalDowntime: '', alarms: '', comment: '' };
    
    if (!shiftRef.current) {
      newErrorMessages.shift = 'Shift is required.';
      valid = false;
    }
    if (!footprintRef.current) {
      newErrorMessages.footprint = 'Footprint is required.';
      valid = false;
    }
    if (!totalDowntimeRef.current.trim()) {
      newErrorMessages.totalDowntime = 'DT is required.';
      valid = false;
    }
    if (!alarmsRef.current.trim()) {
      newErrorMessages.alarms = 'Alarms is required.';
      valid = false;
    }
    if (!commentTextRef.current.trim()) {
      newErrorMessages.comment = 'Comment is required.';
      valid = false;
    }

    setErrorMessages(newErrorMessages);

    if (!valid) return;

    try {
      await onSave({
        shift: shiftRef.current,
        footprint:footprintRef.current,
        user: currentUser,
        comment: commentTextRef.current,
        totaldowntime: totalDowntimeRef.current,
        alarms: alarmsRef.current,
      });
      await clearFields();
    } catch (err) {
      setErrorMessages((prev) => ({ ...prev, comment: 'Failed to save comment. Please try again.' }));
    }
  };

  const handleCancel = async () => {
    if (mode !== 'edit'){
      await clearFields();  
    }    
    await onClose();
  };

  const clearFields = async () => {
    setShift('');
    setFootprint('');
    setCommentText('');
    setTotalDowntime('');
    setAlarms('');
    setIsListening(false);    
    setErrorMessages({
      shift: '',
      footprint: '',
      totalDowntime: '',
      alarms: '',
      comment: ''
    });

    await stopContinuousRecognitionAsync();
  };

  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: isMobile ? '90%' : '580px',
      maxWidth: '100%',
      maxHeight: '90vh',
      padding: '20px',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch', // Smooth scrolling on mobile devices
      paddingRight: '20px',
      boxSizing: 'border-box',
    },
  });


  const iconButtonStyles = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  return (
      <Modal
        isOpen={isOpen}
        onDismiss={onClose}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className="modal-header" style={{ display: 'flex', alignItems: 'center' }}>
          <h2>{mode === 'edit' ? 'Edit Comment' : 'Add New Comment'}</h2>

          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={onClose}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        iconProps={{
          iconName: isListening ? 'Microphone' : 'MicOff2',
        }}
        title={isListening ? 'Listening': 'Speak'}
        onClick={startConversationLoop}
        styles={{ root: {
          transition: 'transform 0.3s ease, background-color 0.3s ease',
      transform: isListening ? `scale(${1 + voiceVolume / 100})` : 'scale(1)',
      color: isListening ? 'blue' : 'black',
      backgroundColor: isListening
        ? `rgba(0, 0, 255, ${0.1 + voiceVolume / 100})`
        : 'transparent',
      borderRadius: '50%', // Rounded button to emphasize the microphone
        } }}
      />      
    </div>

        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal={!isMobile} tokens={{ childrenGap: 10 }}>
            <Stack.Item grow={isMobile}>
              <Dropdown
                label="Shift"
                placeholder="Select a shift"
                options={shiftOptions}
                disabled={mode === 'edit'}
                selectedKey={shift}
                onChange={(event, option) => handleDropdownChange(event, option, setShift, 'shift')}
                styles={dropdownStyles(isMobile)}
              />
              {errorMessages.shift && <div style={{ color: 'red' }}>{errorMessages.shift}</div>}
            </Stack.Item>

            <Stack.Item grow={isMobile}>
              <TextField
                label="Footprint"
                name="footprint"
                value={footprint}
                disabled={mode === 'edit'}
                placeholder="Enter footprint"
                onChange={handleInputChange}
                styles={textFieldStyles(isMobile)}
              />
              {errorMessages.footprint && <div style={{ color: 'red' }}>{errorMessages.footprint}</div>}
            </Stack.Item>
          </Stack>

          <Stack horizontal={!isMobile} tokens={{ childrenGap: 10 }}>
            <Stack.Item grow={isMobile}>
              <TextField
                label="DT (min)"
                name="totalDowntime"
                value={totalDowntime}
                disabled={mode === 'edit'}
                placeholder="Enter total downtime"
                onChange={handleInputChange}
                styles={textFieldStyles(isMobile)}
              />
              {errorMessages.totalDowntime && <div style={{ color: 'red' }}>{errorMessages.totalDowntime}</div>}
            </Stack.Item>

            <Stack.Item grow={isMobile}>
              <TextField
                label="Alarms"
                name="alarms"
                value={alarms}
                disabled={mode === 'edit'}
                placeholder="Enter alarms"
                onChange={handleInputChange}
                styles={textFieldStyles(isMobile)}
              />
              {errorMessages.alarms && <div style={{ color: 'red' }}>{errorMessages.alarms}</div>}
            </Stack.Item>
          </Stack>

          <TextField
            label="User"
            name="user"
            value={currentUser}
            disabled
            styles={textFieldStyles(isMobile)}
          />

          <TextField
            label="Comment"
            name="comment"
            multiline
            rows={7}
            placeholder="This is where the comment can go"
            value={commentText}
            onChange={handleInputChange}
            styles={commentStyles(isMobile)}
          />
          {errorMessages.comment && <div style={{ color: 'red' }}>{errorMessages.comment}</div>}

          <Stack horizontal={!isMobile} tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <DefaultButton text="Cancel" onClick={handleCancel} styles={buttonStyles(isMobile).default} />
            <PrimaryButton text={isSaving ? "Saving..." : "Save"} onClick={handleSave} styles={buttonStyles(isMobile).primary} disabled={isSaving} />
          </Stack>
        </Stack>
      </Modal>
  );
};


const buttonStyles = (isMobile) => ({
  primary: {
    root: { backgroundColor: '#00439D', borderColor: '#00439D', width: isMobile ? '95%' : '20%' },
    rootHovered: { backgroundColor: '#003377', borderColor: '#003377' },
    rootPressed: { backgroundColor: '#002355', borderColor: '#002355' },
  },
  default: {
    root: { backgroundColor: '#FFFFFF', borderColor: '#00439D', width: isMobile ? '95%' : '20%' },
    rootHovered: { backgroundColor: '#F0F0F0', borderColor: '#003377' },
    rootPressed: { backgroundColor: '#E0E0E0', borderColor: '#002355' },
  }
});

const textFieldStyles = (isMobile) => ({
  root: {
    width: isMobile ? '95%' : 262,
  },
  fieldGroup: {
    background: '#EEEEEE'
  },
  subComponentStyles: {
    label: {
      root: {
        fontFamily: 'Segoe UI',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '16px',
        textAlign: 'left',
        color: '#616161'
      },
    },
  },
});

const commentStyles = (isMobile) => ({
  root: {
    width: isMobile ? '95%' : '100%',
  },
  fieldGroup: {
    background: '#FFFFFF'
  },
  subComponentStyles: {
    label: {
      root: {
        fontFamily: 'Segoe UI',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '16px',
        textAlign: 'left',
        color: '#616161'
      },
    },
  },
});

const dropdownStyles = (isMobile) => ({
  root: {
    width: isMobile ? '95%' : 262,
  },
  dropdown: {
    backgroundColor: '#EEEEEE',
  },
  title: {
    backgroundColor: '#EEEEEE',
  },
  label: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    color: '#616161'
  }
});

export default CommentModal;
