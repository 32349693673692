import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
    Checkbox,
    Input,
    Button,
    Label,
    Divider,
    Link,
    Text
} from '@fluentui/react-components';
import { FilterRegular, FilterFilled } from "@fluentui/react-icons";
import { PrimaryButton } from "@fluentui/react";

const INITIAL_FILTERS = {
    shifts: [],
    footprints: [],
    users: [],
    downtimeMin: '',
    downtimeMax: '',
    alarmMin: '',
    alarmMax: ''
};

const FilterDropdown = ({ items, onApplyFilters, appliedFilters, setAppliedFilters }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState(appliedFilters);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedCategories, setExpandedCategories] = useState({});
    const dropdownRef = useRef(null);

    const appliedFiltersCount = useMemo(() => 
        Object.values(filters).reduce((acc, val) => 
            Array.isArray(val) ? acc + val.length : (val !== '' ? acc + 1 : acc), 0),
        [filters]
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const uniqueValues = useMemo(() => ({
        shifts: [...new Set(items.map(item => item.Shift))].sort(),
        footprints: [...new Set(items.map(item => item.Footprint))].sort(),
        users: [...new Set(items.map(item => item.User))].sort()
    }), [items]);

    const handleCheckboxChange = useCallback((category, value) => {
        setFilters(prev => ({
            ...prev,
            [category]: prev[category].includes(value)
                ? prev[category].filter(v => v !== value)
                : [...prev[category], value]
        }));
    }, []);

    const handleInputChange = useCallback((category, value) => {
        setFilters(prev => ({ ...prev, [category]: value }));
    }, []);

    const handleApplyFilters = useCallback(() => {
        onApplyFilters(filters);
        setIsOpen(false);
    }, [filters, onApplyFilters]);

    const handleResetFilters = useCallback(() => {
        setFilters(INITIAL_FILTERS);
        setAppliedFilters(INITIAL_FILTERS);
        setSearchTerm('');
        setExpandedCategories({});
    }, [setAppliedFilters]);

    const filteredValues = useCallback((category) => {
        return uniqueValues[category].filter(value =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [uniqueValues, searchTerm]);

    const toggleCategory = useCallback((category) => {
        setExpandedCategories(prev => ({
            ...prev,
            [category]: !prev[category]
        }));
    }, []);

    const renderCheckboxes = useCallback((category) => {
        const values = filteredValues(category);
        const isExpanded = expandedCategories[category];
        const displayValues = isExpanded ? values : values.slice(0, 3);

        return (
            <>
                {displayValues.map(value => (
                    <Checkbox
                        key={value}
                        label={value}
                        checked={filters[category].includes(value)}
                        onChange={() => handleCheckboxChange(category, value)}
                        style={{ flex: '0 0 50%' }}
                    />
                ))}
                {values.length > 3 && (
                    <Link
                        onClick={() => toggleCategory(category)}
                        style={{ marginTop: '5px', cursor: 'pointer' }}
                    >
                        {isExpanded ? 'View less' : 'View more'}
                    </Link>
                )}
            </>
        );
    }, [filteredValues, expandedCategories, filters, handleCheckboxChange, toggleCategory]);

    const calculateDropdownPosition = () => {
        if (!dropdownRef.current) return {};
        
        const viewportWidth = window.innerWidth;
        const isNarrowScreen = viewportWidth < 500;
        
        return {
            position: 'absolute',
            top: '100%',
            right: isNarrowScreen ? '0' : 'auto',
            left: isNarrowScreen ? '50%' : 'auto',
            transform: isNarrowScreen ? 'translateX(-50%)' : 'none',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            zIndex: 1000,
            width: isNarrowScreen ? '90vw' : '300px',
            maxWidth: '95vw',
            maxHeight: '80vh',
            overflowY: 'auto',
            padding: '10px',
        };
    };
    
    return (
        <div ref={dropdownRef} style={{ position: 'relative' }}>
            <Button
                icon={isOpen ? <FilterFilled /> : <FilterRegular />}
                appearance={isOpen ? "subtle" : "default"}
                onClick={() => setIsOpen(!isOpen)}
                style={{border: "none"}}
            >
                Filter {appliedFiltersCount > 0 && `(${appliedFiltersCount})`}
            </Button>
            {isOpen && (
                <div style={calculateDropdownPosition()}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h3 className='font-primary' style={{ margin: 0, fontSize: '12px' }}>Filter by</h3>
                        <Text
                            style={{
                                fontSize: '12px',
                                color: '#3e65d7',
                                fontFamily: 'system-ui',
                                fontWeight: 600,
                                cursor: 'pointer'
                            }}
                            onClick={handleResetFilters}
                        >
                            Reset
                        </Text>
                    </div>
                    <Divider style={{ marginBottom: '10px', width: '100%' }} />
                    <Input
                        appearance='filled-darker'
                        placeholder="Type a keyword"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ marginBottom: '10px', width: '100%' }}
                    />
                    {['shifts', 'footprints', 'users'].map(category => (
                        <div key={category} style={{ marginBottom: '10px' }}>
                            <Label style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '14px' }}>{category.charAt(0).toUpperCase() + category.slice(1)}</Label>
                            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', fontSize: '14px' }}>
                                {renderCheckboxes(category)}
                            </div>
                        </div>
                    ))}
                    <RangeInput
                        label="DT (min)"
                        minValue={filters.downtimeMin}
                        maxValue={filters.downtimeMax}
                        onMinChange={(value) => handleInputChange('downtimeMin', value)}
                        onMaxChange={(value) => handleInputChange('downtimeMax', value)}
                    />
                    <RangeInput
                        label="Alarm (instances)"
                        minValue={filters.alarmMin}
                        maxValue={filters.alarmMax}
                        onMinChange={(value) => handleInputChange('alarmMin', value)}
                        onMaxChange={(value) => handleInputChange('alarmMax', value)}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <PrimaryButton styles={buttonStyles.primary} size="large" text='Apply' onClick={handleApplyFilters} />
                    </div>
                </div>
            )}
        </div>
    );
};

const RangeInput = ({ label, minValue, maxValue, onMinChange, onMaxChange }) => (
    <div style={{ marginBottom: '10px' }}>
        <Label style={{ fontWeight: 'bold', marginBottom: '10px' }}>{label}</Label>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: "10px" }}>
            <div style={{ width: '45%' }}>
                <Text style={{ fontSize: '12px', marginBottom: '5px' }}>From</Text>
                <Input
                    appearance='filled-darker'
                    value={minValue}
                    onChange={(e) => onMinChange(e.target.value)}
                    style={{ width: '100%' }}
                />
            </div>
            <span style={{ margin: '0 5px' }}>-</span>
            <div style={{ width: '45%' }}>
                <Text style={{ fontSize: '12px', marginBottom: '5px' }}>To</Text>
                <Input
                    appearance='filled-darker'
                    value={maxValue}
                    onChange={(e) => onMaxChange(e.target.value)}
                    style={{ width: '100%' }}
                />
            </div>
        </div>
    </div>
);

const buttonStyles = {
    primary: {
        root: { backgroundColor: '#00439D', borderColor: '#00439D' },
        rootHovered: { backgroundColor: '#003377', borderColor: '#003377' },
        rootPressed: { backgroundColor: '#002355', borderColor: '#002355' },
    },
};

export default FilterDropdown;